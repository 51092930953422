<template>
  <div>
    <v-simple-table
      class="table-padding-2-no-top"
      fixed-header
      height="calc(100vh - 145px)"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <DateRangeFilter
                label="Thời gian"
                placeholder="Thời gian"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="warningTypeOptions"
                label="Phân loại"
                placeholder="Phân loại"
                name="warning_type"
                sort-name="warning_type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <InputFilter
                label="Nhân viên"
                placeholder="Nhân viên"
                name="employee_name"
                sort-name="employee_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader">
              <SelectFilter
                :options="departments"
                :label="$t('labels.department')"
                :placeholder="$t('labels.department')"
                name="id_department"
                sort-name="department"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader">
              <SelectFilter
                :options="positions"
                :label="$t('labels.position')"
                :placeholder="$t('labels.position')"
                name="id_position"
                sort-name="position"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader">
              <InputFilterFromTo
                label="Thời gian ngừng"
                placeholder="Thời gian ngừng"
                name="rest_time"
                sort-name="rest_time"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="workTypeOptions"
                label="Việc"
                placeholder="Việc"
                name="work_type"
                sort-name="work_type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader">
              <InputFilterFromTo
                label="Xử lý"
                placeholder="Xử lý"
                name="work_quantity"
                sort-name="work_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader">
              <InputFilterFromTo
                label="Chưa xử lý"
                placeholder="Chưa xử lý"
                name="wait_quantity"
                sort-name="wait_quantity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="typeOptions"
                label="Phân loại"
                placeholder="Phân loại"
                name="type"
                sort-name="work_type"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>

            <th role="columnheader" class="text-center">
              <InputFilter
                label="Mã"
                placeholder="Mã"
                name="warning_config_code"
                sort-name="warning_config_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`i_${index}_${item.id}`"
            class="text-center"
          >
            <td>{{ item.warehouse_code }}</td>
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ $t(`labels.warning_type_${item.warning_type}`) }}</td>
            <td>{{ item.employee_name }}</td>
            <td>{{ item.department }}</td>
            <td>{{ item.position }}</td>
            <td>{{ secondTimeHuman(item.rest_time) }}</td>
            <td>{{ $t(`labels.work_type_${item.work_type}`) }}</td>
            <td>{{ item.work_quantity }}</td>
            <td>{{ item.wait_quantity }}</td>
            <td>{{ item.type == 1 ? "Sản phẩm" : "Đơn hàng" }}</td>
            <td>{{ item.warning_config_code }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <v-btn color="primary" @click="showDialogConfig">{{
          $t("labels.config")
        }}</v-btn>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>

    <v-dialog v-model="dialogConfig" persistent max-width="1024px">
      <WarningConfigDialog
        v-if="dialogConfig"
        @closeDialog="hideDialogConfig"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import {
  YES_NO_OPTIONS,
  WARNING_TYPES,
  WARNING_WARNING_TYPES,
  WARNING_WORK_TYPES,
} from "@/libs/const";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    WarningConfigDialog: () =>
      import("@/components/employee/WarningConfigDialog"),
  },
  name: "WarningEmployeeTable",
  data: () => ({
    items: [],
    configs: [],
    owners: [],
    filters: {},
    isLoading: false,
    dialogConfig: false,
    page: 1,
    totalPage: 1,
    warehouses: [],
    detail: {},
    yesNoOptions: [...YES_NO_OPTIONS],
    warningTypeOptions: [...WARNING_WARNING_TYPES],
    workTypeOptions: [...WARNING_WORK_TYPES],
    typeOptions: [...WARNING_TYPES],
    departments: [],
    positions: [],
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
    this.getDepartmentAndPosition();
  },
  methods: {
    showDialogConfig() {
      this.dialogConfig = true;
    },
    hideDialogConfig() {
      this.dialogConfig = false;
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    async getDepartmentAndPosition() {
      const { data } = await httpClient.post("/list-department-position");
      this.departments = [...data.departments].map((e) => ({
        value: e.id,
        text: e.department,
      }));
      this.positions = [...data.positions].map((e) => ({
        value: e.id,
        text: e.position,
      }));
    },
    getList: debounce(function () {
      httpClient
        .post("/warning-list", {
          ...this.filters,
          page: this.page,
          viewMode: this.viewOption,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
  },
};
</script>
